import React from "react";
import firebase from "./firebase.config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import "./SigninCombined.css";
import { redirect, useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL, cookieCreator, cookieFetcher } from "../../helper";
import CountDownButton from "../../components/CountDownButton/CountDownButton";
import Countdown from "react-countdown";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { BsWindowSidebar } from "react-icons/bs";
import withNavigation from "../../withNavigation";

class SigninCombined extends React.Component {
  constructor(props) {
    super(props);

    // Redirect using JavaScript
    this.redirectUsingJS = this.redirectUsingJS.bind(this);
  }
  state = {
    showotp: false,
    mobile: "",
    otp: "",
    loadingNumber: false,
    loadingOtp: false,
    agree: true,
    warning: false,
    recaptchaVerifier: null,
    countdownKey: 0,
  };

  CountdownComponent = ({ onSignInSubmit, state, thisCopy }) => {
    const CountdownRenderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Countdown is complete, display a message
        return (
          <div
            className="countDownButtonActive"
            style={{ cursor: "pointer" }}
           onClick={this.handleResendOTP}
          >
            Resend OTP
          </div>
        );
      } else {
        return (
          <div className="countDownButtonInactive">
            {String(seconds).padStart(2, "0")}s
          </div>
        );
      }
    };

    return (
      <div>
        <Countdown date={Date.now() + 30000} renderer={CountdownRenderer} />
      </div>
    );
  };

  handleResendOTP = () => {
    this.onSignInSubmit(this.state.mobile); // Resend OTP logic
    this.setState((prevState) => ({
      countdownKey: prevState.countdownKey + 1 // Restart countdown
    }));
  };

  componentDidMount() {
    const token = cookieFetcher("token");
    if (token) {
      document.location.href = "/";
    }
    this.configureCaptcha();
  }

  componentDidUpdate() {
    console.log("Printing the state", this.state.recaptcha);
  }

  redirectUsingJS() {
    // Use the history object to redirect
    const { history } = this.props;
    history.push("/home");
  }

  checkPresence = async () => {
    const res = await fetch(
      `${BASE_URL}/vendor/check_number/${this.state.mobile}`
    );
    const value = await res.json();
    if (value.message === "Already Vendor Exits !!") {
      console.log("Vendor Exists!!");
      cookieCreator("token", value.token);
      cookieCreator("number", value.data.phone);
      cookieCreator("vendorId", value.data._id);
      cookieCreator("username", value.data.username);
      return true;
    }
    return false;
  };

  configureCaptcha = () => {
    if (!this.state.recaptcha) {
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response) => {
            //this.onSignInSubmit();
            console.log("Recaptcha verified");
          },
          "expired-callback": () => {
            console.warn("Recaptcha expired");
          },
          defaultCountry: "IN",
        }
      );
      this.setState({ recaptcha: recaptchaVerifier });
    }
  };

  onSignInSubmit = (e) => {
    console.log("e",e)
    if (e.length!=10){
      alert("Please enter a valid phone number");
      return;
    }
    if (this.state.agree === false) {
      this.setState({ warning: true });
      return;
    }
    if (typeof e === "object") {
      e.preventDefault();
    }
    let phoneNumber = "";
    if (typeof e === "string" && e.length === 10) {
      phoneNumber = "+91" + e;
    }
    this.setState({mobile:phoneNumber})
    //else {
    //   phoneNumber = "+" + this.state.mobile;
    // }
    if (!this.state.recaptcha) {
      console.log("hello world");
      this.configureCaptcha();
    }
    console.log("phone here",phoneNumber);
    this.setState({ loadingNumber: true });
    const appVerifier = this.state.recaptcha;
    console.log("recaptcha", appVerifier);
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, this.state.recaptcha)
      .then((confirmationResult) => {
        console.log("confirm",confirmationResult);
        window.confirmationResult = confirmationResult;
        console.log("OTP has been sent");
        this.setState({ loadingNumber: false, showotp: true });
      })
      .catch((error) => {
        console.log("error",error);
        console.log("SMS not sent");
        this.setState({ loadingNumber: false });
      });
  };

  onSubmitOTP = (e) => {
    this.setState({ loadingOtp: true });
    let code = "";
    if (this.state.otp.trim() === '') {
      alert("Please enter OTP")
      this.setState({ loadingOtp: false });
      return;
    }
    if (typeof e === "string") {
      code = e;
    }
    if (typeof e === "object") {
      e.preventDefault();
      code = this.state.otp;
    }
    console.log("OTP entered:", code);

    if (!window.confirmationResult) {
      console.error("No confirmationResult available.");
      this.setState({ loadingOtp: false });
      return;
    }

    // console.log("windows", window.confirmationResult);

    window.confirmationResult
      .confirm(code)
      .then((result) => {
        console.log("OTP verification successful:", result.user.refreshToken);
        cookieCreator("refreshToken", result.user.refreshToken);
        this.checkPresence()
          .then((presence) => {
            console.log("Presence check:", presence);
            if (!presence) {
              this.props.handleRedirect("/signup", {
                mobile: this.state.mobile,
              });
            } else {
              this.props.handleRedirect("/home", { mobile: this.state.mobile });
            }
          })
          .catch((err) => {
            console.error("Error during presence check:", err);
            this.setState({ loadingOtp: false });
          });
      })
      .catch((error) => {
        console.error("OTP verification failed:", error);
        this.setState({ loadingOtp: false });
        Swal.fire({
          icon: "error",
          title: "OTP Verification Failed",
          text: "The OTP you entered is incorrect. Please try again.",
        });
      });
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    return (
      <div className="signInPage">
        <Helmet
          title="Sign In | Lofaz Web"
          link={{
            rel: "icon",
            type: "image/png",
            href: cookieFetcher("photo"),
          }}
        ></Helmet>
        <div id="sign-in-button"></div>
        {this.state.showotp ? (
          <div className="otpPanel">
            <div className="otpPanelComp">
              <div className="OTPTitleText">OTP Verification</div>
              <div className="OTPInstructionText">
                Enter the verification code we sent to
              </div>
              <div className="OTPNumberText">{this.state.mobile}</div>
            </div>
            <div className="otpPanelComp">
              <div className="OTPInputLabel">
                Type your 6 digit security code
              </div>
              <form
                className="otpInputPanelwithButton"
                onSubmit={this.onSubmitOTP}
              >
                <OtpInput
                  containerStyle={{ display: "flex", gap: "5px" }}
                  inputStyle={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "5px",
                    border: "solid 1px #c4c2c2",
                  }}
                  value={this.state.otp}
                  onChange={(e) => {
                    this.setState({ otp: e });
                    if (e.length == 6) {
                      this.onSubmitOTP(e);
                    }
                  }}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
                {!this.state.loadingOtp ? (
                  <div className="OTPSubmitAndResend">
                    <div className="OTPSubmit" onClick={this.onSubmitOTP}>
                      Submit
                    </div>
                    <this.CountdownComponent key={this.state.countdownKey}/>
                  </div>
                ) : (
                  <div className="OTPSubmitAndResend">
                    <div
                      className="OTPSubmit"
                      style={{ background: "#79cdfc" }}
                    >
                      <FontAwesomeIcon icon={faSpinner} spin></FontAwesomeIcon>
                    </div>
                    <this.CountdownComponent key={this.state.countdownKey} />
                  </div>
                )}
              </form>
            </div>
            <div className="OTPNumberUpdate otpPanelComp">
              {/* <div className='OTPNumberUpdate1 OTPNumberUpdateComp '>
                {' '}
                Didn’t get the code ?
                <span style={{ color: '#009ef7' }} onClick={() => {}}>
                  {' '}
                  Resend{' '}
                </span>
              </div> */}
              <div className="OTPNumberUpdate2 OTPNumberUpdateComp">
                <span style={{ color: "#009ef7" }} onClick={this.handleReload}>Wrong Number ? </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="signInPanel">
            <div className="imageHolderForSignIn">
              <img src="https://ik.imagekit.io/kotharitech/Lofaz/Lofaz-admin/lofaz_logo.png?updatedAt=1716464379919" style={{height:'150px' ,width:'150px'}}></img>
            </div>
            <div className="signInTitle" style={{marginBottom:'-20px'}}>Sign Up / Sign In</div>
            <div className="signInFormComp">
              <form
                className="signInFormWithOTP"
                onSubmit={this.onSignInSubmit}
              >
                <div id="sign-in-button"></div>
                <PhoneInput
                  country={"in"}
                  onlyCountries={["in"]}
                  name="mobile"
                  onChange={(e) => {
                    console.log(e, "is of length", e.length);
                    this.setState({ mobile: e });
                    if (e.length === 10) {
                      this.onSignInSubmit(e);
                    }
                  }}
                  disableDropdown
                  disableCountryCode
                  placeholder="Enter your 10-digit mobile number"
                ></PhoneInput>
                {!this.state.loadingNumber ? (
                  <div
                    className="verifyButton"
                    style={
                      this.state.agree
                        ? { background: "#009ef7" }
                        : { background: "#79cdfc" }
                    }
                    onClick={this.onSignInSubmit}
                  >
                    
                    Verify
                  </div>
                ) : (
                  <div
                    className="verifyButton"
                    style={{ background: "#79cdfc" }}
                    onClick={this.onSignInSubmit}
                  >
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
                )}
              </form>
            </div>
            {/* <div className="agreeButton">
              <input
                type="checkBox"
                onClick={() => {
                  this.setState({ agree: !this.state.agree });
                }}
                checked={this.state.agree}
              ></input>
              <div>
                I Agree to the{" "}
                <span style={{ color: "#009ef7" }}> Terms & Conditions</span>{" "}
                and
                <span style={{ color: "#009ef7" }}> Privacy Policy</span>
              </div>
            </div> */}
            {/* {this.state.warning && (
              <div style={{ color: "red", fontSize: "12px", fontWeight: 500 }}>
                {" "}
                Please agree to the terms and conditions
              </div>
            )} */}
            <div
              style={{ fontSize: "12px", fontWeight: 500, color: "#6c757d" }}
            >
              By continuing, you agree to our{" "}
              <a
                href="https://lofaz.com/terms-conditions"
                style={{ color: "#009ef7" }}
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://lofaz.com/privacy-policy"
                style={{ color: "#009ef7" }}
              >
                Privacy Policy.
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withNavigation(SigninCombined);
